import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import PageSections from "../components/pageSections/index.js";

// markup
const ContactUsPage = ({ data, pageContext: { originalPath }, uri }) => {
  const pageNode = data?.page?.nodes[0];
  if (!pageNode) {
    return null;
  }

  return (
    <Layout
      title={pageNode.title}
      description={pageNode.description}
      uri={uri}
      image={pageNode.image?.localFile.childImageSharp.original.src}
      imageAlt={pageNode.image?.description}
      mainNav={data.mainNav?.nodes[0]}
      brands={data.brands.nodes[0].brands}
      globalNav={data.globalNav?.nodes[0]}
      footerNav={{
        primary: data.primaryFooterNav?.nodes[0],
        secondary: data.secondaryFooterNav?.nodes[0],
      }}
      originalPath={originalPath}
      headerTitle={pageNode.headerTitle}
      headerBackgroundImage={pageNode.headerBackgroundImage}
      settings={data.settings?.nodes[0]}
      hasDeco={false}
    >
      <PageSections sections={pageNode.sections} />
    </Layout>
  );
};

export default ContactUsPage;

export const data = graphql`
  query contactUsPageQuery($hrefLang: String!) {
    globalNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "global" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    mainNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "main" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    brands: allContentfulPageSectionBrands(
      limit: 1
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        brands {
          name
          slug
        }
      }
    }
    primaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-primary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    secondaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-secondary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    settings: allContentfulSiteSettings(
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...SiteSettings
      }
    }
    page: allContentfulPage(
      filter: {
        contentfulid: { eq: "contact-us" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...Page
      }
    }
  }
`;
